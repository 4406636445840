/**
* ### Manrope-Regular
* Since default font weight is normal, this font is applied by 
* default everywhere when no font-weight is mentioned.
*/

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'),
    url(./fonts/Manrope/static/Manrope-Regular.ttf) format('truetype');
  /* IMPORTANT: font-weight should be added otherwise fonts are messed up */
  font-weight: normal;
  font-display: swap;
}

/**
* ### Manrope-ExtraBold
* This font is applied everywhere when
* - "font-weight": "bold" (in native style tag)
* - "fontWeight": "bold" (in mui components)
*/
@font-face {
  font-family: 'Manrope';
  src: local('Manrope'),
    url(./fonts/Manrope/static/Manrope-ExtraBold.ttf) format('truetype');
  /* IMPORTANT: font-weight should be added otherwise fonts are messed up */
  font-weight: bold;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'Manrope', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
